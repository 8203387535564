import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "company": "Uber",
  "title": "Software Engineering Intern",
  "time": "Winter 2022",
  "index": 4,
  "type": "position",
  "hidden": false,
  "splash": "Routing & distributing all Uber's ingress traffic"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://www.uber.com/"
      }}>{`Uber`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` Jan - Apr 2022`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`Go`}</inlineCode>{`, `}<inlineCode parentName="p">{`C++`}</inlineCode>{`, `}<inlineCode parentName="p">{`gRPC`}</inlineCode>{`, `}<inlineCode parentName="p">{`Protobuf`}</inlineCode>{`, `}<a parentName="p" {...{
        "href": "https://www.envoyproxy.io/"
      }}>{`Envoy`}</a>{`, `}<a parentName="p" {...{
        "href": "https://abseil.io/"
      }}>{`Abseil`}</a></p>
    <p><strong parentName="p">{`Location:`}</strong>{` Remote in Canada`}</p>
    <p><strong parentName="p">{`Team:`}</strong>{` Software Networking Edge`}</p>
    <hr></hr>
    <p>{`At Uber I worked on the Software Networking Edge team which is responsible for functionality including `}<strong parentName="p">{`DNS, Request Sharding, Routing, Load Balancing, Metrics, Observability, etc.`}</strong>{` Throughout the term, I was able to work on a few different projects including contributing to Envoy, and improving our request sharding implementation.`}</p>
    <h2>{`Envoy Contributions`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/envoyproxy/envoy"
      }}>{`Envoy`}</a>{` is a high performance L7 distributed proxy written in `}<inlineCode parentName="p">{`C++`}</inlineCode>{` and originally developed by `}<a parentName="p" {...{
        "href": "https://www.lyft.com/"
      }}>{`Lyft`}</a>{`. Envoy is an official `}<strong parentName="p">{`Cloud Native Computing Foundation`}</strong>{` project, seeing widespread adoption and community support.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Envoy is industry standard`}</strong>{` - used by: Uber, Google, Microsoft, Netflix, Amazon, Snapchat, Salesforce, Airbnb, Stripe, and more`}</p>
    </blockquote>
    <p>{`Over the course of my internship at Uber I was able to make two large changes in `}<a parentName="p" {...{
        "href": "https://github.com/envoyproxy/envoy"
      }}>{`Envoy`}</a>{` as well as another smaller usability improvement. The most impactful of these changes is the `}<a parentName="p" {...{
        "href": "https://github.com/envoyproxy/envoy/pull/20142"
      }}>{`Buffered Tap Sink`}</a>{` which was used to help mitigate a `}<strong parentName="p">{`global Uber Eats outage`}</strong>{`.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/envoyproxy/envoy/pull/20142"
        }}>{`Buffered Tap Sink`}</a>{` - Feature`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/envoyproxy/envoy/issues/19942"
        }}>{`Admin Config IDs Endpoint`}</a>{` - Feature`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/envoyproxy/envoy/pull/20108"
        }}>{`Code format path matching`}</a>{` - Usability`}</li>
    </ol>
    <p>{`Of these changes, I was able to open source the `}<a parentName="p" {...{
        "href": "https://github.com/envoyproxy/envoy/pull/20142"
      }}>{`Buffered Tap Sink`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/envoyproxy/envoy/pull/20108"
      }}>{`Code format path matching`}</a>{`. I implemented the `}<a parentName="p" {...{
        "href": "https://github.com/envoyproxy/envoy/issues/19942"
      }}>{`Admin Config IDs Endpoint`}</a>{` in our own fork of envoy but did not get around to open sourcing it during my term. This change will be open sourced shortly by Uber.`}</p>
    <p><em parentName="p">{`For a deeper dive into my contributions to Envoy, see `}<a parentName="em" {...{
          "href": "#open-source-envoy"
        }}>{`here`}</a>{`.`}</em></p>
    <h2>{`Request Sharding`}</h2>
    <p>{`During the second half of my internship with Uber, I worked on improving our implementation of geo location based request sharding.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Sharding`}</strong>{` is a common strategy used to scale applications by dividing the ingress work into different compute units (shards) and assigning each shard to a different server.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Geo location based sharding`}</strong>{` is a sharding scheme based on the latitude / longitude information that is provided with a request when it hits Envoy.`}</p>
    </blockquote>
    <p>{`My work with geo location based sharding had a few goals. First, we hoped to vastly reduce the load on backend configuration services, and second, we wished to improve Envoy's fault tolerance against config provider outages. My project accomplished both objectives, resulting in:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`>97%`}</strong>{` load reduction on sharding configuration services`}</li>
      <li parentName="ul">{`Significantly improved fault tolerance achieved by separating envoy and the sharding configuration cache. This separation allows envoy to perform restarts without affecting the cache, so an Envoy that restarts during a config provider outage will still be able to fetch a valid sharding configuration.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      